import { getDateISOString } from '@/utils/dateString';
import {
  CLASS_TYPE,
  PRICE_OPTIONS,
  FILTER_TIME_OPTIONS,
} from '@/utils/filterOptions.js';
import intl from '@/utils/intl';
import { addDays, getISODay } from 'date-fns';

export const makeSearchQueryForLogUserEvent = (query, categoryList) => {
  const {
    keyword,
    order,
    selectedAge,
    selectedPrice,
    onlyDiscounted,
    selectedClassType,
    selectedCategory,
    selectedSubCategory,
    selectedPeriodOption,
    selectedDayOfWeek,
    startTimeIdx,
    endTimeIdx,
  } = query;

  const returnData = {};

  if (keyword) returnData.keyword = keyword;
  if (order) returnData.list_type = order;
  if (selectedAge) returnData.selected_age = selectedAge;
  if (selectedPrice) {
    PRICE_OPTIONS.filter(p => p.value === selectedPrice).forEach(price => {
      returnData.min_price = price.minPrice;
      returnData.max_price = price.maxPrice;
    });
  }

  // is_discount는 true or false로 늘 들어갑니다.
  returnData.is_discount = !!onlyDiscounted;

  if (selectedClassType) {
    returnData.class_type = selectedClassType.split(',').reduce((acc, cur) => {
      const text = CLASS_TYPE.find(ct => ct.value === parseInt(cur))?.text;
      return text ? acc.concat(text) : acc;
    }, []);
  }

  returnData.main_category_id = getCategoryId(selectedCategory);
  returnData.sub_category_id = getCategoryId(selectedSubCategory);
  returnData.main_category_name = getMainCategoryName(
    selectedCategory,
    categoryList,
  );
  returnData.sub_category_name = getSubCategoryName({
    selectedCategory,
    selectedSubCategory,
    categoryList,
  });

  if (selectedPeriodOption || selectedDayOfWeek || startTimeIdx || endTimeIdx)
    returnData.is_set_schedule = true;

  return returnData;
};

const getCategoryId = categoryIdstr => {
  if (!categoryIdstr) return undefined;
  const selectedCategoryId = Number(categoryIdstr);
  if (isNaN(selectedCategoryId)) return undefined;
  return [selectedCategoryId];
};

const getMainCategoryName = (selectedCategory, categoryList) => {
  if (!selectedCategory) return undefined;
  const mainCategory = categoryList?.find(category => {
    return String(category.id) === String(selectedCategory);
  });
  if (!mainCategory) return undefined;
  const mainCategoryName = mainCategory?.name;
  return [mainCategoryName];
};

const getSubCategoryName = ({
  selectedCategory,
  selectedSubCategory,
  categoryList,
}) => {
  if (!selectedCategory || !selectedSubCategory) return undefined;
  const mainCategory = categoryList?.find(category => {
    return String(category.id) === String(selectedCategory);
  });
  const subCategoryName = mainCategory?.children.find(
    subCategory => String(subCategory.id) === String(selectedSubCategory),
  )?.name;

  if (!subCategoryName) return undefined;
  return [subCategoryName];
};

export const dateToString = (startIsoString, endIsoString) => {
  if (startIsoString === endIsoString) {
    const [, month, date] = startIsoString.split('-');
    return `${+month}월 ${+date}일`;
  } else {
    const [year1, month1, date1] = startIsoString.split('-');
    const [year2, month2, date2] = endIsoString.split('-');

    if (year1 !== year2)
      return `${+year1.slice(-2)}년 ${+month1}월 ${+date1}일 ~ ${+year2.slice(
        -2,
      )}년 ${+month2}월 ${+date2}일`;

    if (month1 === month2) return `${+month1}월 ${+date1}일 ~ ${+date2}일`;

    return `${+month1}월 ${+date1}일 ~ ${+month2}월 ${+date2}일`;
  }
};

const _ageFilterText = ageSelectedList => {
  if (!ageSelectedList || !ageSelectedList.length) return '';

  const ageStringArray = [];
  let temp = null;
  let count = 0;

  // 선택된 age값 배열을 순회하면서 동시에 3개 이상 이어진 숫자가 있다면 ex) 7,8,9 -> 7-9 로 합쳐줍니다
  for (let i = 0; i < ageSelectedList.length; i++) {
    if (temp === null) temp = ageSelectedList[i];

    if (ageSelectedList[i] + 1 === ageSelectedList[i + 1]) {
      count++;
    } else {
      if (count > 1) {
        ageStringArray.push(`${temp}-${ageSelectedList[i]}`);
      } else {
        ageStringArray.push(temp);
        if (count !== 0) ageStringArray.push(ageSelectedList[i]);
      }
      count = 0;
      temp = null;
    }
  }

  // 위에서 아무 값도 안들어갔다면 (전부 다 이어져있다면) 의 예외처리
  if (ageStringArray.length === 0) {
    ageStringArray.push(
      `${ageSelectedList[0]}-${ageSelectedList[ageSelectedList.length - 1]}`,
    );
  }

  // 다 이어붙인 후
  let ageString = ageStringArray.join(', ');

  // 마지막 값이 12면 +살 아니면 살 을 붙여 텍스트를 완성합니다
  ageString += ageString.slice(-2) === '13' ? '+살' : '살';

  return ageString;
};

// 필터 전체 요약 텍스트 만들기
export const allFilterTextMaker = ({ keyword, scheduleText, selectedAge }) => {
  const resultArray = [];
  if (keyword && keyword.length) resultArray.push(keyword);
  else resultArray.push('모든 수업');
  if (scheduleText && scheduleText.length) resultArray.push(scheduleText);
  if (selectedAge && Array.isArray(selectedAge) && selectedAge.length > 0)
    resultArray.push(_ageFilterText(selectedAge));

  return resultArray.join(' / ');
};

export const scheduleTextMaker = ({
  filterPeriodOptions,
  period,
  filterDayOfWeek,
  dayOfWeek,
  filterWeekType,
  weekType,
  filterTimeOptions,
  startTimeIdx,
  endTimeIdx,
  classStartDate,
  classEndDate,
}) => {
  const resultArray = [];

  const currentPeriod = filterPeriodOptions.find(f => f.value === period);
  if (currentPeriod) resultArray.push(currentPeriod.text);

  if (classStartDate) {
    resultArray.push(dateToString(classStartDate, classEndDate));
  }

  const currentWeekType = filterWeekType.find(w => w.value === weekType);
  if (currentWeekType) {
    resultArray.push(currentWeekType.text);
  } else if (dayOfWeek && dayOfWeek.length > 0) {
    resultArray.push(
      dayOfWeek.reduce((acc, cur, i) => {
        const dayText = filterDayOfWeek.find(d => d.value === cur)?.text;
        if (i === 0) return dayText;
        return acc + '/' + dayText;
      }, ''),
    );
  }

  if (startTimeIdx > 0 || endTimeIdx < filterTimeOptions.length - 1) {
    const startTime = filterTimeOptions[startTimeIdx].text;
    const endTime = filterTimeOptions[endTimeIdx].text;

    if (startTime.slice(0, 2) === endTime.slice(0, 2)) {
      resultArray.push(`${startTime} ~ ${endTime.slice(3)}`);
    } else {
      resultArray.push(`${startTime} ~ ${endTime}`);
    }
  }

  return resultArray.join(' · ');
};

export const apiQueryMaker = ({
  keyword,
  age,
  schedule,
  selectedDayOfWeek,
  selectedPeriodOption,
  category,
  classType,
  price,
  order,
  startTimeIdx,
  endTimeIdx,
  difficulty,
  onlyDiscounted,
  classStartDate, // 2023-01-01
  classEndDate, // 2023-01-01
}) => {
  const query = {};

  if (keyword.length > 0) {
    query.keyword = keyword;
  }

  if (age && age.length > 0 && age.length < 18) {
    query.age = JSON.stringify(age);
  }

  if (selectedDayOfWeek.length > 0) {
    query.classDay = JSON.stringify(
      // 0을 7로 변환해서 보냅니다.(ISO week)
      selectedDayOfWeek.map(w => (w === 0 ? 7 : w)),
    );
  }

  if (selectedPeriodOption) {
    const todayISOString = intl.getISOString(new Date());
    const d = new Date();
    const today = new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000);
    let classDateStart = null;
    let classDateEnd = null;

    if (selectedPeriodOption === 'TODAY') {
      classDateStart = todayISOString;
      classDateEnd = todayISOString;
      query.classDay = JSON.stringify([getISODay(today)]);
    }
    if (selectedPeriodOption === 'SEVEN_DAYS') {
      classDateStart = todayISOString;
      classDateEnd = getDateISOString(addDays(today, 7));
      if (!query.classDay) {
        query.classDay = JSON.stringify([1, 2, 3, 4, 5, 6, 7]);
      }
    }
    if (selectedPeriodOption === 'THIRTY_DAYS') {
      classDateStart = todayISOString;
      classDateEnd = getDateISOString(addDays(today, 30));
      if (!query.classDay) {
        query.classDay = JSON.stringify([1, 2, 3, 4, 5, 6, 7]);
      }
    }

    if (selectedPeriodOption === 'SELECT_CALENDAR') {
      if (classStartDate && classEndDate) {
        classDateStart = classStartDate;
        classDateEnd = classEndDate;
        // classDay가 없으면 선택한 기간 범위만큼의 요일정보 배열값을 쿼리에 넣어서 보내야합니다.
        if (!query.classDay) {
          const startDate = new Date(classStartDate);
          const endDate = new Date(classEndDate);
          let startDateTemp = new Date(
            startDate.getTime() + startDate.getTimezoneOffset() * 60 * 1000,
          );
          const endDateTemp = new Date(
            endDate.getTime() + endDate.getTimezoneOffset() * 60 * 1000,
          );
          const weeks = [];
          while (startDateTemp <= endDateTemp) {
            weeks.push(getISODay(startDateTemp));
            startDateTemp = addDays(startDateTemp, 1);
            if (weeks.length > 7) break; // 7번을 넘기지 못하게
          }
          query.classDay = JSON.stringify(weeks.sort((a, b) => a - b));
        }
      }
    }

    if (classDateStart) {
      query.classDateStart = classDateStart;
      query.classDateEnd = classDateEnd;
    }
  }

  // 시간 필터링
  if (startTimeIdx > 0 || endTimeIdx < FILTER_TIME_OPTIONS.length - 1) {
    const startTime =
      FILTER_TIME_OPTIONS[queryStartTimeIdx(startTimeIdx)].value.toString();
    const endTime =
      FILTER_TIME_OPTIONS[queryEndTimeIdx(endTimeIdx)].value.toString();
    query.classTimeStart = `${startTime.padStart(2, 0)}:00`;
    query.classTimeEnd = `${endTime.padStart(2, 0)}:00`;
  }

  if (classType.length) {
    query.classType = JSON.stringify(
      String(classType)
        ?.split(',')
        .map(a => parseInt(a)) || [],
    );
  }

  if (category) query.category = category;

  if (price) {
    query.minPrice = price.minPrice;
    query.maxPrice = price.maxPrice;
  }
  query.onlyDiscounted = onlyDiscounted ? true : undefined;

  if (category && difficulty) query.difficulty = difficulty;

  query.listType = order?.selectedOrder || null;

  return query;
};

/**
 * @param {*} endTimeIdx  '23'
 * @returns 0 ~ [FILTER_TIME_OPTIONS.length - 2]
 */
export function queryStartTimeIdx(startTimeIdx) {
  const maxIndex = FILTER_TIME_OPTIONS.length - 2;
  const numberIdx = Number(startTimeIdx);
  return isNaN(startTimeIdx) || numberIdx < 0
    ? 0
    : numberIdx > maxIndex
    ? maxIndex
    : numberIdx;
}
/**
 * @param {*} endTimeIdx  '23'
 * @returns 1 ~ 14
 */
export function queryEndTimeIdx(endTimeIdx) {
  const maxIndex = FILTER_TIME_OPTIONS.length - 1;
  const numberIdx = Number(endTimeIdx);
  return isNaN(numberIdx) || numberIdx <= 0
    ? maxIndex
    : numberIdx > maxIndex
    ? maxIndex
    : numberIdx;
}

/**
 * @description
 * "9,8" -> [9,8]
 * @return
 */
export function queryNumberList(age) {
  return (
    age
      ?.split(',')
      .map(a => parseInt(a))
      .filter(a => !isNaN(a)) || []
  );
}
