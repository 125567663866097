const ArrowIconSet = {
  arrowLeft: {
    drawings: [
      {
        type: 'path',
        d: 'M38 25.5C38.8284 25.5 39.5 24.8284 39.5 24C39.5 23.1716 38.8284 22.5 38 22.5V25.5ZM11.75 22.5C10.9216 22.5 10.25 23.1716 10.25 24C10.25 24.8284 10.9216 25.5 11.75 25.5L11.75 22.5ZM38 22.5L11.75 22.5L11.75 25.5L38 25.5V22.5Z',
        fill: 'currentColor',
      },
      {
        type: 'path',
        d: 'M20 14L10 24L20 34',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  arrowRight: {
    drawings: [
      {
        type: 'path',
        d: 'M10 24H36.25M28 14L38 24L28 34',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  arrowDown: {
    drawings: [
      {
        type: 'path',
        d: 'M24 10L24 36.25M14 28L24 38L34 28',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  arrowUp: {
    drawings: [
      {
        type: 'path',
        d: 'M24 38L24 11.75M34 20L24 10L14 20',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  caretLeft: {
    drawings: [
      {
        type: 'path',
        d: 'M30 12L18 24L30 36',
        stroke: 'currentColor',
        'stroke-width': '4',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  caretLeftSmall: {
    drawings: [
      {
        type: 'path',
        d: 'M28 16L20 24L28 32',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  caretRight: {
    drawings: [
      {
        type: 'path',
        d: 'M18 36L30 24L18 12',
        stroke: 'currentColor',
        'stroke-width': '4',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  caretRightSmall: {
    drawings: [
      {
        type: 'path',
        d: 'M18.9393 30.9393C18.3536 31.5251 18.3536 32.4749 18.9393 33.0607C19.5251 33.6464 20.4749 33.6464 21.0607 33.0607L18.9393 30.9393ZM28 24L29.0607 25.0607C29.6464 24.4749 29.6464 23.5251 29.0607 22.9393L28 24ZM21.0607 14.9393C20.4749 14.3536 19.5251 14.3536 18.9393 14.9393C18.3536 15.5251 18.3536 16.4749 18.9393 17.0607L21.0607 14.9393ZM21.0607 33.0607L29.0607 25.0607L26.9393 22.9393L18.9393 30.9393L21.0607 33.0607ZM29.0607 22.9393L21.0607 14.9393L18.9393 17.0607L26.9393 25.0607L29.0607 22.9393Z',
        fill: 'currentColor',
      },
    ],
    viewBox: '0 0 48 48',
  },
  caretUp: {
    drawings: [
      {
        type: 'path',
        d: 'M36 30L24 18L12 30',
        stroke: 'currentColor',
        'stroke-width': '4',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  caretUpSmall: {
    drawings: [
      {
        type: 'path',
        d: 'M32 28L24 20L16 28',
        stroke: 'currentColor',
        'stroke-width': '3',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  caretDown: {
    drawings: [
      {
        type: 'path',
        d: 'M12 18L24 30L36 18',
        stroke: 'currentColor',
        'stroke-width': '4',
        'stroke-linecap': 'round',
        'stroke-linejoin': 'round',
      },
    ],
    viewBox: '0 0 48 48',
  },
  caretDownSmall: {
    drawings: [
      {
        type: 'path',
        d: 'M17.0607 18.9393C16.4749 18.3536 15.5251 18.3536 14.9393 18.9393C14.3536 19.5251 14.3536 20.4749 14.9393 21.0607L17.0607 18.9393ZM24 28L22.9393 29.0607C23.5251 29.6464 24.4749 29.6464 25.0607 29.0607L24 28ZM33.0607 21.0607C33.6464 20.4749 33.6464 19.5251 33.0607 18.9393C32.4749 18.3536 31.5251 18.3536 30.9393 18.9393L33.0607 21.0607ZM14.9393 21.0607L22.9393 29.0607L25.0607 26.9393L17.0607 18.9393L14.9393 21.0607ZM25.0607 29.0607L33.0607 21.0607L30.9393 18.9393L22.9393 26.9393L25.0607 29.0607Z',
        fill: 'currentColor',
      },
    ],
    viewBox: '0 0 48 48',
  },
};

const ArrowIconNames = Object.keys(ArrowIconSet);

export { ArrowIconNames, ArrowIconSet };
